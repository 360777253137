<template>
  <div class="row">
    
    <div class="col-7">
        <b-card-code title="Transaction Detail">
            <table class="table table-hover table-ml">
                <tr>
                    <td class="pl-0">ID</td>
                    <td>{{ transaction.id }}</td>
                </tr>
                <tr>
                    <td class="pl-0">UUID</td>
                    <td>{{ transaction.uuid }}</td>
                </tr>
                <tr>
                    <td class="pl-0">User</td>
                    <td>{{ transaction.user.name }} - {{ transaction.user.email }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Type</td>
                    <td style="text-transform: capitalize;">{{ transaction.type }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Transaction Time</td>
                    <td>{{ transaction.time }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Status</td>
                    <td style="text-transform: capitalize;">{{ transaction.status }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Asset</td>
                    <td>{{ transaction.auction.commodity.name }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Depository</td>
                    <td>{{ transaction.auction.warehouse.name }} - {{ transaction.auction.warehouse.location }}</td>
                </tr>
                <tr>
                    <td class="pl-0" style="text-transform: capitalize;">{{ transaction.type }} Price</td>
                    <td>{{ transaction.price_formatted }}</td>
                </tr>
                <tr>
                    <td class="pl-0">Lot</td>
                    <td>{{ transaction.lot }} lots</td>
                </tr>
                <tr>
                    <td  class="p-0" style="text-transform: capitalize;">{{ transaction.type }} Qty</td>
                    <td>{{ transaction.auction.commodity.amount_per_lot * transaction.lot }} {{ transaction.auction.commodity.unit }}</td>
                </tr>
            </table>
        </b-card-code>
    </div>
    <div class="col-5">
        <b-card-code title="Result Detail">
            <table class="table table-hover table-ml">
                <tr>
                    <td class="pl-0">Match Price</td>
                    <td>
                      {{ transaction.fixed_price_formatted }}
                    </td>
                </tr>
                <tr>
                    <td class="pl-0">Matched Lot</td>
                    <td>{{ transaction.lot_complete }} lots</td>
                </tr>
                <tr v-if="transaction.lot_open > 0">
                    <td class="pl-0">Open/Unmatched Lot</td>
                    <td>{{ transaction.lot_open }} lots</td>
                </tr>
                <tr>
                    <td class="pl-0">Qty</td>
                    <td>{{ transaction.qty_complete }} {{ transaction.auction.commodity.unit }}</td>
                </tr>
            </table>
        </b-card-code>
        <b-card-code title="Payment Detail">
          <table class="table table-hover table-ml">
            <tr>
                <td class="pl-0">Transaction Amount</td>
                <td>{{ transaction.net_value_formatted }}</td>
            </tr>
            <tr>
                <td class="pl-0">Fee Transaction (+ VAT for Transaction Fee)</td>
                <td>{{ transaction.admin_fee_formatted }}</td>
            </tr>
            <tr>
                <td class="pl-0">VAT/PPN</td>
                <td>{{ transaction.tax_formatted }}</td>
            </tr>
            <tr>
                <td class="pl-0">Total Transaction Value</td>
                <td>{{ transaction.type == 'bid' ? transaction.total_buy_price_formatted : transaction.total_sell_price_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'bid' && transaction.total_buy_paid > 0 && transaction.buy_margin_paid > 0">
                <td class="pl-0">Margin</td>
                <td>{{ transaction.buy_margin_paid_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'bid' && transaction.total_buy_paid > 0 && transaction.total_unpaid > 0">
                <td class="pl-0">Paid Total</td>
                <td>{{ transaction.total_buy_paid_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'bid' && transaction.total_unpaid > 0">
                <td class="pl-0">Outstanding Transaction</td>
                <td>{{ transaction.total_unpaid_formatted }}</td>
            </tr>
            <tr v-if="transaction.type == 'ask' && transaction.sell_margin_paid > 0">
                <td class="pl-0">Margin</td>
                <td>{{ transaction.sell_margin_paid }}</td>
            </tr>
            <tr v-if="transaction.payment_due_date">
                <td class="pl-0">Settlement Date</td>
                <td>{{ transaction.payment_due_date }}</td>
            </tr>
          </table>
          <b-button v-if="checkPermission('add payment auction transaction') && transaction.type == 'bid' && transaction.total_unpaid > 0" v-b-modal.form-add-payment class="btn btn-info btn-block mb-1 mt-1" data-toggle="tooltip" data-placement="center" title="Add Payment">
            <feather-icon icon="PlusCircleIcon" /> Add Payment
          </b-button>

          <b-modal id="form-add-payment" size="sm" title="Add Payment">
            <div class="form">

              <h5>Add Payment</h5>

              <div class="form-group">
                <label for="amount">Amount:</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{ transaction.user.currency.code }}</span>
                  </div>
                  <input type="number" class="form-control" id="amount" v-model="formPayload.amount" placeholder="Insert payment amount" required>
                </div>
              </div>

            </div>

            <template #modal-footer="{}">
              <div v-if="isLoading">
                <br>
                <b-spinner
                  class="mb-2"
                  variant="primary"
                /><br>
              </div>
              <b-button variant="success" @click="addPayment()" v-bind:disabled="isLoading">
                Submit
              </b-button>
            </template>
          </b-modal>

          <div v-if="transaction.installments.length > 0">
            <hr>
            <h5>Payment History</h5>
            <table class="table">
              <tr>
                <th class="pl-0">Time</th>
                <th>Amount</th>
              </tr>
              <tr v-for="(item, key) in transaction.installments" :key="key">
                <td class="pl-0">{{ item.time }}</td>
                <td>{{ item.amount_formatted }}</td>
              </tr>
            </table>
          </div>
        </b-card-code>
    </div>
    <div class="col-12">
        <b-card-code no-body title="Detail Match Lists">
            <div class="table-responsive-sm">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th v-if="transaction.type == 'ask'">Buyer</th>
                  <th v-if="transaction.type == 'bid'">Seller</th>
                  <th>Match Price</th>
                  <th>Lot</th>
                  <th>Qty</th>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(matched, index) in transaction.matcheds" v-bind:key="index">
                  <td>{{ matched.id }}</td>
                  <td v-if="transaction.type == 'ask'">{{ matched.bid_transaction.user.name }}</td>
                  <td v-if="transaction.type == 'bid'">{{ matched.ask_transaction.user.name }}</td>
                  <td>{{ matched.price }}</td>
                  <td>{{ matched.lot }} Lot</td>
                  <td>{{ matched.qty }}</td>
                  <td>{{ matched.time }}</td>
                  <td class="text-capitalize">{{ matched.status }}</td>
                </tr>
                <tr v-if="transaction.matcheds.length == 0">
                  <td colspan="7" class="text-center">Data is empty.</td>
                </tr>
              </tbody>
            </table>

            <div class="alert alert-info m-1 p-1">
              <strong>Match Status:</strong><br>
              <ul>
                <li><strong>Matched</strong>: Transaction has been matched.</li>
                <li><strong>Success</strong>: Settlement complete, Asset balance successfully transferred to buyer, cash balance has been transferred to Seller.</li>
                <li><strong>Failed</strong>: Settlement failed.</li>
              </ul>
            </div>
          </div>
        </b-card-code>
    </div>

  </div>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title () {
    return `Detail Transaction`
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  components: {
    BCard,
    BCardText,
    BCardCode,
    BButton
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      transaction: Object,
      isLoading: false,
      formPayload: {
        amount: 0
      },
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/admin/auction-transactions/' + this.uuid).then(response => {
        this.transaction = response.data.data;
      })
    },
    addPayment() {
      this.$http.post('/admin/auction-transactions/' + this.uuid + '/add-payment', this.formPayload)
        .then(response => {

          this.$bvModal.hide('form-add-payment')
          this.getData()
          successNotification(this, 'Success', 'Auction successfully created')

          this.isLoading = false;
          this.formPayload.amount = 0;

        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)   
          }
          
          this.isLoading = false;
        })
    }
  }
}
</script>